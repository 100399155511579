import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import storage from 'store'

// 导入全局样式
import './assets/css/global.css'

// 导入swiper
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

// 导入ElementUi
//import  './plugins/element'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'


Vue.use(ElementUI);

// 通过this.$echarts使用
Vue.prototype.$echarts = window.echarts

Vue.config.productionTip = false

Vue.prototype.$storage = storage
// 配置axois
// 挂载后台数据

axios.defaults.baseURL =   'https://www.qmdaipai.com/index.php/'
  //'https://qm.n.xilukeji.com/index.php/'
  //'https://www.qmdaipai.com/index.php/'
//设置axios为form-data
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';

 
axios.interceptors.request.use(function (config) {
  // 发送请求前要做的操作
  //console.log(config);
  if(config.url!='api/common/upload'){
    config.transformRequest = [function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      ret = ret.substring(0,ret.length-1);
      return ret
   }]
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
